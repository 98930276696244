<template>
  <v-app>
    <v-main class="px-4">
      <div class="text-center py-4">
        <v-avatar class="bg-blue-lighten-1" size="112">
          <v-img src="@/assets/logoclear.png" max-width="66" max-height="66"></v-img>
        </v-avatar>
        <div class="mt-4">
          <div class="text-blue-grey-darken-3 text-h4 font-weight-bold">
            Giftit
          </div>
          <div class="text-grey">
            thousands of gift ideas
          </div>
        </div>
      </div>

      <v-sheet max-width="600" class="mx-auto px-4 my-4">
        <v-row dense>
          <v-col>
            <v-card
              variant="outlined"
              density="compact"
              color="blue"
              prepend-icon="mdi-apple"
              title="iOS App"
            >
            <template #subtitle>
              <div>
                Early 2025
              </div>
            </template>
            </v-card>
          </v-col>
          
          <v-col>
            <v-card
              variant="outlined"
              density="compact"
              prepend-icon="mdi-android"
              title="Android App"
            >
            <template #subtitle>
              <div>
                Mid 2025
              </div>
            </template>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>

      <v-container class="text-center" max-width="660">
        Giftit makes it easy and simple to choose the perfect gift from thousands of options, turning the search into a fun and effortless experience. With our intuitive tools and diverse selection, finding the right present has never been more enjoyable.
      </v-container>

      <v-container class="mb-4 px-0" max-width="630">
        <v-card
          v-for="i in items"
          :key="i"
          :text="i.body"
          variant="text"
        >
        <template v-slot:prepend>        
          <v-icon :color="i.color">{{ i.icon }}</v-icon> 
        </template>
        <template #title>
          <div :class="'text-' + i.color" class="text-capitalize">{{ i.title }}</div>
        </template>
        </v-card>
      </v-container>

      <v-divider></v-divider>

      <v-container class="text-right text-caption text-grey">
        All rights reserved. © Giftit, {{ currentYear }}
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    items: [
      // {
      //   icon: "mdi-gift-open",
      //   color: "red-darken-1",
      //   title: "thousands of gift ideas",
      //   body: "The application offers thousands of unique gift ideas, turning the search for the perfect present into an exciting and engaging experience. With intuitive filters and personalized recommendations, finding gifts becomes not only easy but also enjoyable, making every occasion truly special"
      // },
      {
        icon: "mdi-check-decagram",
        color: "blue-darken-1 ",
        title: "only verified marketplaces",
        body: "Our platform features only trusted marketplaces, such as Amazon, which are relied upon by millions of people worldwide. We prioritize quality and reliability, ensuring that every gift idea comes from reputable sources you can trust."
      },
      {
        icon: "mdi-lock",
        color: "green-darken-1 ",
        title: "totally private",
        body: "We respect our users’ privacy, which is why our application does not collect any personal information. Your experience is completely anonymous, allowing you to explore and enjoy gift ideas with full peace of mind."
      }
    ],
  }),
  mounted() {
    document.title = "Giftit • Thousands of Gift Ideas";
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>
